const getStartState = () => ({
	activeRow: -1,
	activeCol: -1,
	grid: [
		["A1", "B1", "C1", "D1"],
		["A2", "B2", "C2", "D2"],
		["A3", "B3", "C3", "D3"],
		["A4", "B4", "C4", "D4"]
	],
});
const cellSize = 80;

class Game extends React.Component {
	constructor() {
		super()
		this.grid = React.createRef();
		this.state = {random: false, rand: 0}
	}
	reset = () => {
		this.grid.current.reset();
	}
	shuffle = () => {
		this.grid.current.shuffle();
	}
	toggleRandom = () => {
		this.setState({
			random: !this.state.random,
			rand: this.state.rand + 1,
		});
	}
	render() {
		let className = 'game' + (this.state.random ? ' random' : '');
		return (
			<div className={className}>
				<Grid ref={this.grid} />
				<div className="buttons">
					<button onClick={this.reset}>Reset</button>
					<button onClick={this.shuffle}>Shuffle</button>
					<button onClick={this.toggleRandom}>Random Background</button>
				</div>
				<style type="text/css">{`
					.random .cell, .random .cell:before, .random .cell:after {
						color: transparent;
						background-image: url("https://picsum.photos/640/640/?random&i=${this.state.rand}");
					}
				`}</style>
			</div>
		)
	}
}

class Grid extends React.Component {
	moving: false
	moveDir: null
	startPosition: null

	constructor() {
		super()
		this.state = getStartState();
	}
	reset = () => {
		this.setState(getStartState());
	}
	shuffle = () => {
		let grid = deepShuffle(this.state.grid);
		this.setState({grid: grid});
	}
	reset = (e) => {
		this.setState(getStartState())
	}
	onMouseDown = (e) => {
		let pos = e;
		if (e.targetTouches) {
			pos = e.targetTouches[0]
		}
		this.setState({moving: true, dx: 0, dy: 0})
		this.startPosition = {x: pos.clientX, y: pos.clientY}
	}
	onMouseMove = (e) => {
		let pos = e.targetTouches ? e.targetTouches[0] : e
		let dx = pos.clientX - this.startPosition.x
		let dy = pos.clientY - this.startPosition.y

		if (!this.moveDir) {
			if (Math.abs(dx) > 20) {
				this.moveDir = 'x'
				this.setState({
					dx: dx,
					activeRow: +e.target.dataset.y,
					activeCol: -1,
				})
			}
			else if (Math.abs(dy) > 20) {
				this.moveDir = 'y'
				this.setState({
					dy: dy,
					activeRow: -1,
					activeCol: +e.target.dataset.x,
				})
			}
		}
		else if (this.moveDir == 'x') {
			this.setState({ dx: dx })
		}
		else if (this.moveDir == 'y') {
			this.setState({ dy: dy })
		}
	}
	onMouseUp = (e) => {
		if (this.moveDir) {
			if (this.moveDir == 'x') {
				let dx = this.state.dx
				let moves = Math.round(dx / cellSize);
				if (moves < 0 ) moves += 4;
				while (moves--) moveRight(this.state.grid, this.state.activeRow)
			}
			else if (this.moveDir == 'y') {
				let dy = this.state.dy
				let moves = Math.round(dy / cellSize);
				if (moves < 0 ) moves += 4;
				while (moves--) moveDown(this.state.grid, this.state.activeCol)
			}
			this.setState({grid: this.state.grid});
		}
		this.setState({
			moving: false,
			dx: 0,
			dy: 0,
			activeRow: -1,
			activeCol: -1,
		})
		this.moveDir = null
		this.startPosition = null
	}

	render() {
		let {grid, activeRow, activeCol, dx, dy, moving} = this.state
		let onMove = this.state.moving ? this.onMouseMove : undefined;
		let className = moving ? this.moveDir == 'x' ? 'grid x-dir' : 'grid y-dir' : 'grid';

		return (
			<div className={className} onMouseDown={this.onMouseDown} onTouchStart={this.onMouseDown} onMouseMove={onMove} onTouchMove={onMove} onMouseUp={this.onMouseUp} onTouchEnd={this.onMouseUp} onTouchCancel={this.onMouseUp}>
				{grid.map((row, r) => (
					<div key={r} className="row">
						{row.map((cell, c) => {
							let active = r == activeRow || c == activeCol
							let style = null
							if (active && moving) {
								style = {
									transform: `translate3d(${dx}px, ${dy}px, 0)`
								}
							}
							let className = 'cell' + (active ? ' active' : '') + ' ' + cell
							return <span data-x={c} data-y={r} style={style} key={c} className={className}>{cell}</span>
						})}
					</div>
				))}
			</div>
		)
	}
}

function moveRight(grid, row) {
	let r = grid[row];
	r.unshift(r.pop());
}
function moveDown(grid, col) {
	let last = grid[grid.length - 1][col];
	for (let i = grid.length - 1; i >= 0; i--) {
		if (i == 0) {
			grid[i][col] = last;
		} else {
			grid[i][col] = grid[i - 1][col];
		}
	}
}
function shuffle(arr) {
	for (let i = 0; i < arr.length; i++) {
		let rand = Math.random() * (i + 1) | 0
		// no swap if equal
		if (i !== rand) {
			let v1 = arr[i]
			let v2 = arr[rand]
			arr[rand] = v1
			arr[i] = v2
		}
	}
}
function deepShuffle(arr2d) {
	let innerDepth = arr2d[0].length;
	let flattened = [].concat(...arr2d);
	shuffle(flattened);
	let deepened = [];
	for (let i = 0; i < flattened.length; i += innerDepth) {
		deepened.push(flattened.slice(i, i + innerDepth));
	}

	return deepened;
}

let g = ReactDOM.render(<Game />, document.querySelector('#content'));
